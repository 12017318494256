import logo from "assets/logo.svg";
import { useState } from "react";
import { FiArrowLeft, FiMenu } from "react-icons/fi";
import { Link } from "react-router-dom";
import SideMenu from "./SideMenu";
import UserMenu from "./UserMenu";
import DateTime from "./DateTime";
import Info from "./Info";
import useApp from "hooks/useApp";

export default function Header() {
  const [showSideMenu, setShowMenu] = useState(false);
  const {user} = useApp();
  return (
    <div className="w-full bg-dark relative z-40">
      <div className="mx-auto max-w-screen-3xl flex items-center h-16 px-3">
        <div className="relative z-50 flex items-center gap-5">
          <div
            onClick={() => setShowMenu(!showSideMenu)}
            className="cursor-pointer duration-300"
          >
            {!showSideMenu && <FiMenu size="30" />}
            {showSideMenu && <FiArrowLeft size="30" />}
          </div>
          <Link to="/">
            <img src={logo} className="h-8" alt="logo" />
          </Link>
        </div>
        <div className="flex-1" />
        <DateTime />
        {/* <div className="mx-2">
          <Info />
        </div> */}
        
        <UserMenu />
        <SideMenu
          closeMenu={() => setShowMenu(false)}
          showSideMenu={showSideMenu}
        />
      </div>
    </div>
  );
}
