import {
  deleteUser,
  getAllUsers,
  getAllUsersForAdmin,
  getRoles,
} from "api/users";
import Loader from "components/shared/Loader";
import { useEffect, useMemo, useState } from "react";
import Debounce from "utilities/Debounce";
import RoleDropdown from "./RoleDropdown";
import useApp from "hooks/useApp";
import { FiTrash2 } from "react-icons/fi";
import classNames from "utilities/ClassNames";

export enum Roles {
  Admin = "admin",
  User = "user",
}
export default function UsersContainer() {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [data, setData] = useState<any>(null);
  const [rawData, setRawData] = useState<any>(null);
  const [RolesData, setRolesData] = useState<any>(null);
  const [refresh, setrefresh] = useState(false);
  const { userRole, isScaled } = useApp();

  const getAdminData = async () => {
    setLoading(true);
    const response: any = await getAllUsersForAdmin();
    if (response.status === 200) {
      setRawData(response.data);
      const resp2 = await getRoles();
      if (resp2 && resp2.status === 200) {
        setRolesData(resp2.data);
      }
    } else {
      setError(true);
    }

    setLoading(false);
  };

  const getData = async () => {
    setLoading(true);
    const response: any = await getAllUsers();
    if (response.status === 200) {
      setRawData(response.data);
    } else {
      setError(true);
    }
    setLoading(false);
  };

  const refreshData = async () => {
    if (userRole === "admin") {
      const response: any = await getAllUsersForAdmin();
      if (response.status === 200) {
        setRawData(response.data);
      } else {
        setError(true);
      }
    } else {
      const response: any = await getAllUsers();
      if (response.status === 200) {
        setRawData(response.data);
      } else {
        setError(true);
      }
    }
  };

  useEffect(() => {
    if (userRole === "admin") {
      getAdminData();
    } else {
      getData();
    }
  }, [refresh, userRole]);

  useEffect(() => {
    if (rawData && rawData.length) {
      setData(rawData);
    }
  }, [rawData]);

  const [keyword, setKeyword] = useState("");
  const [debouncedKeyword, setDebouncedKeyword] = useState("");
  Debounce(() => setDebouncedKeyword(keyword), [keyword], 500);

  const rows = useMemo(() => {
    let items = data;
    const keyword = debouncedKeyword.toString().toLowerCase();
    if (keyword !== "") {
      items = data?.filter(
        (item: any) =>
          item.tagNameIdentifier.toLowerCase().includes(keyword) ||
          item.name.toLowerCase() === keyword
      );
    }
    return items;
  }, [debouncedKeyword]);

  const deleteHandler = async (id: number, name: string) => {
    const text = `Are you sure you want to delete ${name}?`;
    if (window.confirm(text) === true) {
      // await deleteUser(id).then(
      //   (response) => {
      //     setrefresh(true);
      //   },
      //   (responseError) => {
      //     setrefresh(false);
      //   }
      // );
    }
  };

  return (
    <>
      {loading && (
        <div className="w-full h-[80vh] grid place-content-center">
          <Loader />
        </div>
      )}
      {error && (
        <div className="w-full h-[80vh] grid place-content-center">
          <p>Error loading data</p>
        </div>
      )}
      {!loading && !error && data && (
        <div
          className={classNames(
            isScaled ? "text-2xl" : "text-base",
            "pl-3 pt-3 pb-3 overflow-hidden overflow-y-scroll h-full"
          )}
        >
          <div className="flex w-full items-center justify-between rounded-md">
            <div className="flex gap-3">
              <div>
                <input
                  onChange={(e: any) => setKeyword(e.target.value)}
                  className="input"
                  type="text"
                  placeholder="Search"
                />
              </div>
            </div>
          </div>
          <div className="gradient p-0.5 rounded-md mt-3">
            <div className="p-3 rounded-md bg-black h-full max-h-[750px] overflow-scroll">
              <table className="w-full border-collapse">
                <thead>
                  <tr className="text-green">
                    {/* <th className="text-left" >Id</th> */}
                    <th className="text-left">Name</th>
                    {/* {userRole==="admin" && <th className="text-left">Skype</th>}
                   {userRole==="admin" && <th className="text-left">Points</th>} */}
                    {userRole === Roles.Admin && (
                      <th className="text-left">Phone Number</th>
                    )}
                    {userRole === Roles.Admin && (
                      <th className="text-left">Email</th>
                    )}
                    {userRole === Roles.Admin && (
                      <th className="text-left">Role</th>
                    )}
                    {/* {userRole==="admin" && <th className="text-left">Actions</th>} */}
                  </tr>
                </thead>
                <tbody>
                  {data.map((user: any) => (
                    <tr key={user.id} className="border-b border-b-lightGray">
                      {/* <td>{user.id}</td> */}
                      <td>{user.name}</td>
                      {userRole === Roles.Admin && <td>{user.phoneNumber}</td>}
                      {userRole === Roles.Admin && <td>{user.email}</td>}
                      {userRole === Roles.Admin && (
                        <td>
                          <div>
                            <RoleDropdown
                              id={user.id}
                              currentrole={
                                user.role
                                  ? user.role.charAt(0).toUpperCase() +
                                  user.role.slice(1)
                                  : user.role
                              }
                              rolesData={RolesData}
                              refreshhandler={refreshData}
                            />
                          </div>
                        </td>
                      )}
                      {/* {userRole==="admin" &&<td>
                        <FiTrash2
                          size={20}
                          className="mt-1.5"
                          color="cyan"
                          onClick={() => {
                            deleteHandler(user.id, user.firstName);
                          }}
                        />
                      </td>} */}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
