  import Loader from "components/shared/Loader";
  import { useEffect, useMemo, useState } from "react";
  import Debounce from "utilities/Debounce";
  import useApp from "hooks/useApp";
  
  export default function DigitalTwinContainer() {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [data, setData] = useState<any>(null);
    const [rawData, setRawData] = useState<any>(null);
    const [RolesData, setRolesData] = useState<any>(null);
    const [refresh, setrefresh] = useState(false);
    const { userRole } = useApp();

    const dt:any =[
        {
            id:1,
            name:'EnergyHub',
            to:`${process.env.REACT_APP_ENERGY_APP}`
        },
        {
            id:2,
            name:'Al Khobar Water Tower',
            to:`${process.env.REACT_APP_CULTURAL_APP}`
        },
        {
          id:3,
          name:'Real Estate',
          to:`${process.env.REACT_APP_REALESTATE_APP}`
        }
    ];

  
    const [keyword, setKeyword] = useState("");
    const [debouncedKeyword, setDebouncedKeyword] = useState("");
    Debounce(() => setDebouncedKeyword(keyword), [keyword], 500);
  
    const rows = useMemo(() => {
      let items = data;
      const keyword = debouncedKeyword.toString().toLowerCase();
      if (keyword !== "") {
        items = data?.filter(
          (item: any) =>
            item.tagNameIdentifier.toLowerCase().includes(keyword) ||
            item.name.toLowerCase() === keyword
        );
      }
      return items;
    }, [debouncedKeyword]);
  
  
    return (
      <>
        {/* {loading && (
          <div className="w-full h-[80vh] grid place-content-center">
            <Loader />
          </div>
        )} */}
        {/* {error && (
          <div className="w-full h-[80vh] grid place-content-center">
            <p>Error loading data</p>
          </div>
        )} */}
        {dt && (
          <div className="pl-3 pt-3 pb-3 overflow-hidden overflow-y-scroll h-full">
            <div className="flex w-full items-center justify-between rounded-md">
              <div className="flex gap-3">
                <div>
                  <input
                    onChange={(e: any) => setKeyword(e.target.value)}
                    className="input"
                    type="text"
                    placeholder="Search"
                  />
                </div>
              </div>
            </div>
            <div className="gradient p-0.5 rounded-md mt-3">
              <div className="p-3 rounded-md bg-black h-full max-h-[650px] overflow-scroll">
                <table className="w-full border-collapse">
                  <thead>
                    <tr className="text-green">
                      <th className="text-left">Id</th>
                      <th className="text-left">Digital Twin</th>
                    </tr>
                  </thead>
                  <tbody>
                    {dt.map((twin: any) => (
                      <tr key={twin.id} className="border-b border-b-lightGray">
                        <td>{twin.id}</td>
                        <td className="twinlist" onClick={()=>window.location.replace(twin.to)}>{twin.name}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
  