import useApp from "hooks/useApp";
import map from "../../assets/screenshot4.png";
import { useNavigate } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { GoogleMap, Marker, useJsApiLoader } from "@react-google-maps/api";
import { FaMapPin } from "react-icons/fa";
export default function Dashboard() {
  const [loading, setLoading] = useState(true);
  const url = "../../assets/Screenshot.png";
  const containerStyle = {
    width: "100vw",
    height: "100vh",
  };
  const center = {
    lat: 24.898999,
    lng: 42.388945,
  };
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyCaAnlPllExl2Yuk5G6RENFb0zlBQUXFWY",
  });
  useEffect(() => {
    if (isLoaded) {
      setLoading(false);
    }
  }, [isLoaded]);
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const styles = require("./googlemapstyles.json");
  const [map, setMap] = React.useState(null);
  const onLoad = React.useCallback(function callback(map: any) {
    // This is just an example of getting and using the map instance!!! don't just blindly copy!
    const bounds = new window.google.maps.LatLngBounds(center);
    map.fitBounds(bounds);
    setMap(map);
  }, []);
  const onUnmount = React.useCallback(function callback(map: any) {
    setMap(null);
  }, []);
  const CustomMarkerIcon = (
    <div style={{ color: "red", fontSize: "24px" }}>
      <FaMapPin />
    </div>
  );
  return !loading ? (
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={center}
      zoom={6.5}
      // onLoad={onLoad}
      // onUnmount={onUnmount}
      options={{
        styles: styles,
        zoomControl: false,
        mapTypeControl: false,
        fullscreenControl: false,
        minZoom: 3,
        maxZoom: 7,
      }}
    >
      <Marker
        label={{
          text: "Water Tower",
          color: "#12E4DE",
          className: "watertowertext",
        }}
        position={{ lat: 26.4, lng: 50 }}
        onClick={() => {
          window.open(`${process.env.REACT_APP_CULTURAL_APP}`, "_self");
        }}
      />
      <Marker
        label={{
          text: "Energy",
          color: "#12E4DE",
          className: "watertowertext",
        }}
        position={{ lat: 27.7, lng: 48.8 }}
        onClick={() => {
          window.open(`${process.env.REACT_APP_ENERGY_APP}`, "_self");
        }}
      />

<Marker
        label={{
          text: "Leap24",
          color: "#12E4DE",
          className: "watertowertext",
        }}
        position={{ lat: 25.2, lng: 46.4 }}
        onClick={() => {
          window.open(`${process.env.REACT_APP_LEAP24_APP}`, "_self");
        }}
      />

<Marker
        label={{
          text: "Data Center",
          color: "#12E4DE",
          className: "watertowertext",
        }}
        position={{ lat: 24.7, lng: 46.85 }}
        onClick={() => {
          window.open(`${process.env.REACT_APP_DATACENTER_APP}`, "_self");
        }}
      />

      <Marker
        label={{
          text: "Car Simulator",
          color: "#12E4DE",
          className: "watertowertext",
        }}
        position={{ lat: 21, lng: 39.3 }}
        onClick={() => {
          window.open(`${process.env.REACT_APP_REALESTATE_APP}`, "_self");
        }}
      />
      {/* Child components, such as markers, info windows, etc. */}
      <></>
    </GoogleMap>
  ) : (
    <></>
  );
}