import { getUserInfo } from "api/user";
import Loader from "components/shared/Loader";
import { createContext, useContext, useEffect, useState } from "react";
import { hasAuthParams, useAuth } from "react-oidc-context";
export const AppContext = createContext<any | null>(null);
export const AppProvider = ({ children }: { children: any }) => {
  const [isLoggedIn, setIsLoggedIn] = useState("loggedIn");
  const [user, setUser] = useState<any>(null);
  const [token, setToken] = useState<any>(null);
  const [userRole, setuserRole] = useState<any>(null);
  const [dateTime, setDateTime] = useState<any>(null);
  const [loading, setLoading] = useState(false);
  // useEffect(() => {
  //   setLoading(true);
  //   (async () => {
  //     const response: any = await getUserInfo();
  //     if (response && response.status === 200) {
  //       setUser(response.data);
  //       setuserRole(response.data.roles);
  //       setIsLoggedIn("loggedIn");
  //     } else {
  //       setIsLoggedIn("loggedOut");
  //     }
  //   })();
  //   setLoading(false);
  // }, []);
  // useEffect(() => {
  //   if (userRole){
  //     if( userRole.includes("admin")) {
  //      setuserRole("admin");
  //    } else {
  //      setuserRole("user");
  //    }
  //   }
  // }, [userRole]);

  const auth = useAuth();
  const [hasTriedSignin, setHasTriedSignin] = useState(false);

  useEffect(() => {
    (async () => {
      if(auth.isAuthenticated){
        const response= await getUserInfo();
        if(response.status===200){
          setUser(response.data);
          setuserRole(response.data.role);
        }
      }
        })();
  }, [auth]);

    useEffect(() => {
    if (userRole) {
      if (userRole.includes("admin")) {
        setuserRole("admin");
      } else {
        setuserRole("user");
      }
    }
  }, [userRole]);

  // automatically sign-in
  useEffect(() => {
    if (
      !hasAuthParams() &&
      !auth.isAuthenticated &&
      !auth.activeNavigator &&
      !auth.isLoading &&
      !hasTriedSignin
    ) {
      auth.signinRedirect();
      setHasTriedSignin(true);
    }
  }, [auth, hasTriedSignin]);



  if (auth.isLoading)
    return (
      <div className="bg-black fixed inset-0 grid place-content-center">
        <Loader />
      </div>
    );

  switch (auth.activeNavigator) {
    case "signinSilent":
      return <div>Signing you in...</div>;
    case "signoutRedirect":
      return <div>Signing you out...</div>;
  }
  if (auth.error) {
    return <div>Oops... {auth.error.message}</div>;
  }
  if (!auth.isAuthenticated) {
    return <div>Unable to log in</div>;
  }



  if(auth.isAuthenticated){

    return (
      <AppContext.Provider
        value={{
          isLoggedIn,
          setIsLoggedIn,
          user,
          setUser,
          token,
          dateTime,
          setDateTime,
          userRole,
          setuserRole
        }}
      >
        {children}
      </AppContext.Provider>
    );
  }  
  else{
    return null;
  }
};
export default function useApp() {
  return useContext(AppContext);
}